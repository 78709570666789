import React from "react";
import { Link } from "gatsby";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero__text-wrapper">
        <h1>
          En enkel vei <br />
          til et sunnere liv.
        </h1>

        <Link to="/blog">Til bloggen</Link>
      </div>
      <div className="hero__image-wrapper">
        <img src={require("../assets/bg-bente-2.jpg")} />
      </div>
    </div>
  );
};

export default Hero;
