import React from "react";
import { Link } from "gatsby";
import PortableText from "./portableText";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

const AboutSection = ({ aboutData }) => {
  return (
    <div className="about-section">
      <div className="about-section__content">
        <div className="about-section__image-container">
          {" "}
          <img
            src={imageUrlFor(buildImageObj(aboutData.mainImage))
              .width(500)
              .height(Math.floor((9 / 16) * 900))
              .fit("crop")
              .auto("format")
              .url()}
            alt={aboutData.mainImage.alt}
          />
          {/* <img src={require("../assets/garden.jpg")} /> */}
        </div>

        <div className="about-section__text-container">
          <h4>Hei, jeg heter Bente</h4>

          {aboutData && <PortableText blocks={aboutData._rawAboutMeExtra} />}

          <Link to="/om-meg">Les mer om meg her</Link>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
